<template>
  <div id="uworkswrite">
    <div class="fullWidth atLeft">
      <h2>Works - {{ this.$route.params.id ? 'Edit' : 'Make new' }} release</h2>
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        title
      </div>
      <input type="text" class="fullInput" id="title" v-model="title" placeholder="Title" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        link
      </div>
      <input type="text" class="fullInput" id="href" v-model="href" placeholder="href" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        release date
      </div>
      <input type="date" class="fullInput" id="rDate" v-model="rDate" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        release type
      </div>
      <select name="type" style="padding: 0 10px;" id="type" v-model="type">
        <option :value="0" disabled>SELECT</option>
        <option :value="1">original</option>
        <option :value="2">remix</option>
        <option :value="3">+81 work</option>
      </select>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uWorks">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post(id)" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uWorksWrite',
  data: () => ({
    title: '',
    href: '',
    rDate: '',
    type: 0,
  }),
  mounted() {
    this.get(this.$route.params.id);
  },
  methods: {
    async post(id) {
      if (id) {
        if (this.title == '' || this.rDate == '') {
          alert('Title and release date cannot be empty.');
          return false;
        }
        await this.$firebase
          .firestore()
          .collection('Works')
          .doc(id)
          .update({
            title: this.title,
            href: this.href,
            rDate: this.rDate,
            type: this.type,
          });
        alert('修正完了');
        this.$router.push('/uWorks');
      } else {
        if (this.title == '' || this.rDate == '') {
          alert('Title and release date cannot be empty.');
          return false;
        }
        await this.$firebase
          .firestore()
          .collection('Works')
          .add({
            title: this.title,
            href: this.href,
            rDate: this.rDate,
            date: new Date(),
            type: this.type,
          });
        alert('作成完了');
        this.$router.push('/uWorks');
      }
    },

    async get(id) {
      if (id) {
        const snapshot = await this.$firebase
          .firestore()
          .collection('Works')
          .doc(id)
          .get();
        this.title = snapshot.data().title;
        this.href = snapshot.data().href;
        this.rDate = snapshot.data().rDate;
        this.id = this.$route.params.id;
        this.type = snapshot.data().type;
      }
    },
  },
};
</script>

<style scoped>
#uworkswrite {
  width: 100%;
  min-width: 800px;
}

.info {
  width: 200px;
  height: 54px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
</style>
