<template>
  <div id="uworkswrite">
    <div class="fullWidth atLeft">
      <h2>Works - Edit Clients</h2>
    </div>
    <div>
      <vue-editor v-model="content"></vue-editor>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uWorks">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post(id)" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "uWorksWrite",
  data: () => ({
    content: "",
  }),
  components: { VueEditor },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection("Clients")
        .doc("Content")
        .get();
      this.content = r.data().content;
    },
    async post() {
      const r = await this.$firebase
        .firestore()
        .collection("Clients")
        .doc("Content");
      r.set({ content: this.content }, { merge: true });
      alert("削除完了");
      this.$router.push("/uWorks");
    },
  },
};
</script>

<style>
#uworkswrite {
  width: 100%;
  min-width: 800px;
}
</style>