<template>
  <div id="ulook">
    <div class="fullWidth atLeft">
      <h2>Look</h2>
    </div>
    <div class="fullWidth atLeft">
      <button class="btn" @click="post">Update</button>
    </div>
    <div class="fullWidth text atLeft">
      <spinner v-if="isReady == false" />
      <table v-if="isReady" :style="{ borderColor: $store.state.textColor }">
        <tr :style="{ borderColor: $store.state.textColor }">
          <td>상단 바 색상</td>
          <td colspan="3">
            <input type="color" name="" id="" v-model="headerColor" />
          </td>
        </tr>
        <tr :style="{ borderColor: $store.state.textColor }">
          <td>텍스트/경계선 색상</td>
          <td colspan="3">
            <input type="color" name="" id="" v-model="textColor" />
          </td>
        </tr>
        <tr :style="{ borderColor: $store.state.textColor }">
          <td>배경 이미지</td>
          <td colspan="3">
            <input type="file" name="" id="" accept="image/*" @change="previewImage" />
          </td>
        </tr>
        <tr>
          <td style="text-align:right;">{{ bgUploadValue }}%</td>
          <td colspan="3"><img :src="background" style="width: 250px;" /></td>
        </tr>


        <tr :style="{ borderColor: $store.state.textColor }">
          <td>인트로 이미지</td>
          <td colspan="3">
            <input type="file" name="" id="" accept="image/*" @change="handleIntroImageChange" />
          </td>
        </tr>
        <tr>
          <td style="text-align:right;">{{ introUploadValue }}%</td>
          <td colspan="3"><img :src="introImage" style="width: 250px;" /></td>
        </tr>


        <tr :style="{ borderColor: $store.state.textColor }">
          <td>Access 링크</td>
          <td colspan="3">
            <input type="text" class="announcement" name="" id="" v-model="access" />
          </td>
        </tr>
        <tr :style="{ borderColor: $store.state.textColor }">
          <td>컨택트 안내 문구</td>
          <td colspan="3">
            <input type="text" class="announcement" name="" id="" v-model="announcement" />
          </td>
        </tr>
        <tr class="audio-line">
          <td>징글</td>
          <td colspan="3">
            <input type="file" accept="audio/*" name="" id="" @change="previewAudio" />
          </td>
        </tr>
        <tr>
          <td style="text-align:right;">{{ bgUploadValue }}%</td>
          <td colspan="3"><audio :src="audio" controls></audio></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'ulook',
  data: () => ({
    headerColor: '',
    textColor: '',
    isReady: false,
    audio: null,
    audioData: null,
    uploadValue: 0,
    announcement: '',
    background: '',
    introImage: '',
    bgUploadValue: 0,
    introUploadValue: 0,
    imageData: '',
    access: '',
  }),
  components: {
    Spinner,
  },
  mounted() {
    this.get();
  },
  methods: {
    async post() {
      await this.$firebase
        .firestore()
        .collection('Look')
        .doc('look')
        .set(
          {
            headerColor: this.headerColor,
            textColor: this.textColor,
            audio: this.audio,
            background: this.background,
            introImage: this.introImage,
            announcement: this.announcement,
            access: this.access,
          },
          { merge: true }
        );
      alert('修正完了');
      this.get();
    },
    async get() {
      this.isReady = false;
      const r = await this.$firebase
        .firestore()
        .collection('Look')
        .doc('look')
        .get();

      this.headerColor = r.data().headerColor;
      this.textColor = r.data().textColor;
      this.background = r.data().background;
      this.introImage = r.data().introImage;
      this.audio = r.data().audio;
      this.isReady = true;
      this.announcement = r.data().announcement;
      this.access = r.data().access;
    },
    previewImage(e) {
      this.bgUploadValue = 0;
      this.background = null;
      const rightNow = Date.parse(new Date());
      this.imageData = e.target.files[0];
      const storageRef = this.$firebase
        .storage()
        .ref(`${rightNow + '-' + this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.bgUploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.bgUploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.background = url;
          });
        }
      );
    },

    handleIntroImageChange(e) {
      this.introUploadValue = 0;
      this.introImage = null;
      const rightNow = Date.parse(new Date());
      this.imageData = e.target.files[0];
      const storageRef = this.$firebase
        .storage()
        .ref(`${rightNow + '-' + this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.introUploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.introUploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.introImage = url;
          });
        }
      );
    },

    previewAudio(e) {
      this.uploadValue = 0;
      this.audio = null;
      const rightNow = Date.parse(new Date());
      this.audioData = e.target.files[0];
      const storageRef = this.$firebase
        .storage()
        .ref(`${rightNow + '-' + this.audioData.name}`)
        .put(this.audioData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.audio = url;
          });
        }
      );
    },
  },
};
</script>

<style>
#uabout {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.atLeft {
  justify-content: left;
  text-align: left;
}

.bigText {
  width: 80%;
  height: 80%;
  font-family: serif;
  padding: 10px;
}

.fullWidth {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.text {
  min-width: 700px;
}

.announcement {
  padding: 2px;
  box-sizing: border-box;
  width: 100%;
}

.btn {
  margin: 10px;
  min-width: 70px;
  height: 50px;
  font-family: serif;
  font-size: 15px;
  border-radius: 8px;
  background-color: rgb(127, 127, 255);
  color: white;
}

.ghost {
  display: none;
}
</style>

<style scoped>
table,
tr,
td {
  border-collapse: collapse;
  border: 1px solid;
}

table {
  width: 100%;
}

tr td {
  height: 40px;
  padding: 5px;
  box-sizing: border-box;
}

tr td:nth-child(1) {
  width: 150px;
}

tr td:nth-child(2) {
  width: 100px;
}

tr td:nth-child(3) {
  width: 100px;
}

tr td:nth-child(4) {
  width: 100px;
}
</style>
