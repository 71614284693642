<template>
  <div id="profile">
    <a href="#" @click="open(link)" rel="noopener noreferrer">
      <img :src="pPic" alt class="imgDt" />
    </a>

    <img :src="lPic" alt class="imgMb" style="margin-bottom: 30px;" />
  </div>
</template>
<script>
  export default {
    name: 'Profile',
    data: () => ({
      pPic: '',
      lPic: '',
      link: '',
    }),
    mounted() {
      this.get();
    },
    methods: {
      async get() {
        const r = await this.$firebase
          .firestore()
          .collection('Home')
          .doc('Pictures')
          .get();

        this.pPic = r.data().profile;
        this.lPic = r.data().logo;
        this.link = r.data().link;
      },
      open(link) {
        window.open(link);
      },
    },
  };
</script>
<style>
  #profile {
    width: 100%;
    height: fit-content;
    display: flex;
    align-content: center;
    justify-content: center;
  }
</style>
