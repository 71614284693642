<template>
  <div id="uclients">
    <div class="fullWidth atLeft">
      <h2>Clients</h2>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uClients/uClientsWrite">
        <button class="btn">New client</button>
      </router-link>
    </div>
    <div class="fullWidth atLeft">
      <table style="width: 100%;">
        <tr v-if="clients.length === 0">
          ・No Clients.
        </tr>
        <template v-else>
          <tr v-for="item in clients" :key="item.name">
            <td :style="{ borderColor: $store.state.textColor }">・ {{ item.name }} - {{ item.client }}</td>

            <td style="width: 100px" :style="{ borderColor: $store.state.textColor }">
              <button @click="edit(item.id)">Edit</button>
              <button @click="del(item.id)">Delete</button>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uClients',
  data: () => ({
    clients: [],
  }),
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection('ClientList')
        .orderBy('name', 'asc')
        .get();

      r.forEach((v) => {
        const { name, client, url } = v.data();
        this.clients.push({
          name,
          client,
          url,
          id: v.id
        });
      });
    },
    edit(id) {
      this.$router.push('/uClients/uClientsWrite/' + id);
    },
    async del(id) {
      if (confirm('本当に削除しますか?')) {
        await this.$firebase
          .firestore()
          .collection('ClientList')
          .doc(id)
          .delete();

        alert('削除完了');
        this.clients = [];
        this.get();
      } else {
        return;
      }
    },
  },
};
</script>
<style>
#uclients {
  width: 100%;
}

td {
  border-bottom: 1px solid white;
}
</style>
