<template>
  <div id="unewswrite">
    <div class="fullWidth atLeft">
      <h2>Link - Edit Announcement</h2>
    </div>
    <div>
      <vue-editor v-model="content"></vue-editor>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uLink">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post()" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  data: () => ({
    content: ``,
  }),
  mounted() {
    this.get(this.$route.params.id);
  },
  methods: {
    async post() {
      await this.$firebase
        .firestore()
        .collection("Aiosays")
        .doc("something")
        .set({
          content: this.content,
        });
      alert("Saved");
    },
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection("Aiosays")
        .doc("something")
        .get();

      this.content = r.data().content;
    },
  },
};
</script>

<style>
#unewswrite {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.fullInput {
  font-family: serif;
  width: 80%;
  height: 30px;
  line-height: 30px;
  padding: 10px;
}
</style>