<template>
  <div id="unewswrite">
    <div class="fullWidth atLeft">
      <h2>Home - Edit Logo</h2>
    </div>
    <div class="fullWidth atLeft">
      <div class="logoPreview">
        <img :src="picture" alt style="margin:auto;" />
      </div>
      <div class="logoBox">
        <input type="file" class="halfInput" @change="previewImage" />
      </div>

      <div class="logoProg">{{uploadValue.toFixed() + "%"}}</div>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uHome">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post()" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    imageData: null,
    picture: null,
    uploadValue: 0
  }),
  mounted() {
    this.get();
  },
  methods: {
    async post() {
      await this.$firebase
        .firestore()
        .collection("Home")
        .doc("Pictures")
        .update({
          logo: this.picture
        });
      alert("修正完了");
      this.$router.push("/uHome");
    },

    async get() {
      const snapshot = await this.$firebase
        .firestore()
        .collection("Home")
        .doc("Pictures")
        .get();
      this.picture = snapshot.data().logo;
    },

    previewImage(e) {
      this.uploadValue = 0;
      this.picture = null;
      const rightNow = Date.parse(new Date());
      this.imageData = e.target.files[0];
      const storageRef = this.$firebase
        .storage()
        .ref(`${rightNow + "-" + this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.picture = url;
          });
        }
      );
    }
  }
};
</script>

<style>
#unewswrite {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.fullInput {
  font-family: serif;
  width: 40%;
  height: 30px;
  line-height: 30px;
  padding: 10px;
}

.halfInput {
  font-family: serif;
  padding: 0 0 0 10px;
}

.logoBox {
  height: 140px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.logoProg {
  height: 140px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-style: italic;
  font-weight: 700;
  color: blue;
}

.logoPreview {
  display: flex;
  width: 330px;
  height: 140px;
  justify-content: center;
  border: 1px solid blue;
}
</style>