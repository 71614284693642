<template>
  <div id="footer">
    <hr class="solid" :style="{ borderColor: $store.state.textColor }" />
    <mail />
    <hr class="solid" :style="{ borderColor: $store.state.textColor }" />
    <div class="fullFoot">
      <div class="fullFoot aFooter mobileWidth">
        <div class="textWidth">{{ descA }}</div>
      </div>
      <div class="fullFoot aFooter mobileWidth" style="white-space: pre-line">
        <div class="textWidth">{{ descB }}</div>
      </div>
      <div class="fullFoot aFooter mobileWidth" style="white-space: pre-line">last updated at {{ lastModified }}</div>
      <div class="fullFoot aFooter ftBottom mobileWidth" style>
        website made by
        <a href="http://twitter.com/Titancube" target="_blank" rel="noopener noreferrer"
          :style="{ color: $store.state.textColor }">titancube</a>
      </div>
    </div>
  </div>
</template>

<script>
import Mail from '../components/Mail';
import { format } from 'date-fns';

export default {
  name: 'Afooter',
  components: {
    Mail,
  },
  data: () => ({
    descA: '',
    descB: '',
    lastModified: '2002-02-02',
  }),
  mounted() {
    this.cAget();
  },
  methods: {
    async cAget() {
      const r = await this.$firebase
        .firestore()
        .collection('Copyright')
        .doc('announcement')
        .get();

      const look = await this.$firebase
        .firestore()
        .collection('LastModified')
        .doc('lastmodified')
        .get();

      this.descA = r.data().desc;
      this.descB = r.data().descB;
      this.lastModified = `${this.timeParser(this.timeStruct(look.data().lastmodified.seconds * 1000))}`;
    },

    timeStruct(date) {
      return new Date(date);
    },

    timeParser(time) {
      return format(time, 'yyyy/MM/dd');
    },
  },
};
</script>

<style>
.aFooter {
  white-space: pre-line;
}

.ftBottom {
  margin-bottom: 20px;
}

.fullFoot {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .fullFoot {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
  }

  .mobileWidth {
    width: 90%;
  }
}
</style>
