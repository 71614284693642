<template>
  <div id="news">
    <div class="boardLeft">
      <div class="divTable">
        <table id="newsTable">
          <!-- Make this recursive -->
          <tr v-if="items == ''">
            ・新しいニュースがありません。
            <br />새 소식이 없습니다.
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td class="tTitle" :style="{ borderColor: $store.state.textColor }">
              ・
              <p style="display: inline" v-if="!item.link">{{ item.content }} {{ constructDate(item.date) }}</p>
              <a v-else :href="item.link" target="_blank" :style="{ color: $store.state.textColor }">{{ item.content }} {{
                constructDate(item.date) }}</a>
              <img src="../assets/image/new01.gif" alt class="newFlag" v-if="newCheck(item.id)" />
              <br />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="boardRight">
      <counter />
    </div>
  </div>
</template>
<script>
import Counter from '../components/Counter';
import { format } from 'date-fns';

export default {
  name: 'News',
  components: {
    Counter,
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      const snapshot = await this.$firebase
        .firestore()
        .collection('News')
        .orderBy('date', 'desc')
        .get();
      snapshot.forEach((v) => {
        const { content, link, date } = v.data();
        this.items.push({
          content,
          id: v.id,
          link,
          date: new Date(date.seconds * 1000),
        });
      });
    },
    newCheck: function (e) {
      if (this.items[0].id == e) {
        return true;
      }
    },
    constructDate(date) {
      return `('${format(date, 'yy/MM/dd')})`;
    },
  },
};
</script>
<style>
.boardLeft {
  width: 50%;

  display: flex;
  justify-content: center;
  flex-direction: row;
}

.boardRight {
  width: 50%;
  display: flex;
  justify-content: center;
}

#news {
  display: flex;
  justify-content: center;
  width: 100%;
}

.divTable {
  width: 300px;
  height: 200px;
  overflow: auto;
}

#newsTable {
  width: 100%;
  max-height: 200px;
  margin: 0;
}

tr {
  width: 100%;
  height: 30px;
}

/* .tNew {
  justify-content: center;
  width: 5%;
}
.tNum {
  border-bottom: 1px solid white;
  width: 5%;
} */
.tTitle {
  text-align: left;
  padding: 5px;
  border-bottom: 1px solid white;
  width: 100%;
  line-height: 20px;
  overflow: auto;
  word-break: break-all;
  vertical-align: initial;
}

img.newFlag {
  margin-left: 10px;
  display: inline-flex;
  width: fit-content;
  height: fit-content;
  vertical-align: text-top;
}

@media only screen and (max-width: 768px) {
  #news {
    display: flex;
    flex-wrap: wrap;
  }

  .boardRight {
    width: 90%;
  }

  .boardLeft {
    width: 90%;
    margin-bottom: 30px;
  }

  .divTable {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
