<template>
  <div id="logo">
    <router-link to="/home">
      <img :src="lPic" alt class="imgDt" />

      <a href="#" @click="open(link)" rel="noopener noreferrer">
        <img :src="pPic" alt class="imgMb" />
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data: () => ({
    pPic: '',
    lPic: '',
    link: '',
  }),
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection('Home')
        .doc('Pictures')
        .get();

      this.pPic = r.data().profile;
      this.link = r.data().link;
      this.lPic = r.data().logo;
    },
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style>
#logo {
  height: fit-content;
  display: inline-flex;
}

@media screen and (max-width: 768px) {
  #logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
