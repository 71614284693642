<template>
  <div id="uClientsWrite">
    <div class="fullWidth atLeft">
      <h2>Clients - {{ this.$route.params.id ? 'Edit' : 'Create new' }} client</h2>
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        Name
      </div>
      <input type="text" class="fullInput" id="name" v-model="name" placeholder="Name" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        Client
      </div>
      <input type="text" class="fullInput" id="client" v-model="client" placeholder="Client" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        URL
      </div>
      <input type="text" class="fullInput" id="url" v-model="url" placeholder="URL" />
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uClients">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post(id)" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'uClientsWrite',
  components: {
    VueEditor,
  },
  data: () => ({
    name: '',
    client: '',
    url: '',
  }),
  mounted() {
    this.get(this.$route.params.id);
  },
  methods: {
    async post(id) {
      if (id) {
        if ([this.name, this.client].includes('')) {
          alert('Name and client cannot be empty.');
          return false;
        }
        await this.$firebase
          .firestore()
          .collection('ClientList')
          .doc(id)
          .update({
            name: this.name,
            client: this.client,
            url: this.url,
          });
        alert('修正完了');
        this.$router.push('/uClients');
      } else {
        if ([this.name, this.client].includes('')) {
          alert('Name and client cannot be empty.');
          return false;
        }
        await this.$firebase
          .firestore()
          .collection('ClientList')
          .add({
            name: this.name,
            client: this.client,
            url: this.url,
          });
        alert('作成完了');
        this.$router.push('/uClients');
      }
    },

    async get(id) {
      if (id) {
        const snapshot = await this.$firebase
          .firestore()
          .collection('ClientList')
          .doc(id)
          .get();
        this.name = snapshot.data().name;
        this.client = snapshot.data().client;
        this.url = snapshot.data().url;
        this.id = this.$route.params.id;
      }
    },
  },
};
</script>

<style scoped>
#uClientsWrite {
  width: 100%;
  min-width: 800px;
}

#uploadedImage {
  width: 100%;
}
</style>

<style>
.info {
  width: 120px;
  height: 54px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
</style>
