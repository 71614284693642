<template>
  <div id="home" class="topSpace">
    <div class="left">
      <profile />
    </div>

    <div class="right">
      <logo />
      <amenu />
    </div>
    <div class="bottom">
      <router-view />
    </div>
    <jingle />
    <afooter />
  </div>
</template>

<script>
import Profile from '../components/Profile';
import Logo from '../components/Logo';
import Amenu from '../components/Amenu';
import Afooter from '../components/Afooter';
import Jingle from '../components/Jingle';

export default {
  metaInfo: {
    title: 'home',
    titleTemplate: '%s | aiobahn.net',
    htmlAttrs: {
      lang: 'ja-JP',
    },
    meta: [
      { charset: 'utf-8' },
      // SEO
      { name: 'description', content: 'latest news' },
      { name: 'author', content: 'aiobahn, titancube' },
      // SNS
      { property: 'og:title', content: 'home', template: (chunk) => `${chunk}`, vmid: 'og:title' },
      // mobile
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
  name: 'Home',
  components: { Profile, Logo, Amenu, Afooter, Jingle },
};
</script>

<style>
#home {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-top: 15%; */
}

.bottom {
  width: 100%;
  padding-top: 40px;
}

.right {
  width: 50%;
  align-content: flex-start;
  vertical-align: middle;
  justify-content: left;
  text-align: left;
  margin: auto 0;
  line-height: unset;
}

.left {
  width: 50%;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.imgDt {
  display: initial;
}

.imgMb {
  display: none;
}

@media screen and (max-width: 768px) {
  .left {
    width: 100%;
  }

  .right {
    width: 100%;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .imgDt {
    display: none;
  }

  .imgMb {
    display: initial;
  }
}
</style>
