<template>
  <div id="uHome fullWidth">
    <div id="topArea">
      <div class="uTop atLeft">
        <router-link to="/home">
          <logo />
        </router-link>
      </div>
      <div>현재 로그인: {{ user }}</div>
    </div>
    <div id="bottomArea" class="uBottom fullWidth">
      <div class="uLeft">
        <uMenu />
      </div>

      <div class="uRight">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import uMenu from '../components/uMenu';
import Logo from '../components/Logo';

export default {
  name: 'uHome',
  data: () => ({
    user: '',
  }),
  components: {
    uMenu,
    Logo,
  },
  mounted() {
    this.getCurrentUser();
  },
  methods: {
    async getCurrentUser() {
      if (sessionStorage.getItem('email')) {
        this.user = sessionStorage.getItem('email');
      } else {
        alert('Access denied.');
        this.$router.push('/');
        return false;
      }
    },
  },
};
</script>

<style>
#uHome {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.uLeft {
  display: flex;
  width: 30%;
}

.uLeft * {
  line-height: unset;
}

.uRight {
  display: flex;
  width: 70%;
}

#topArea {
  display: flex;
  justify-content: space-between;
  min-height: 80px;
}

/* Mobile */
@media screen and (max-width: 768px) {
  #topArea {
    flex-wrap: wrap;
    justify-content: center;
  }

  #topArea>div:nth-child(2) {
    margin-top: 40px;
    opacity: 50%;
  }

  #uHome {
    flex-wrap: wrap;
    justify-content: center;
  }

  #bottomArea {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .uLeft {
    width: 100%;
  }

  .uRight {
    width: 100%;
    margin-top: 4rem;
  }
}
</style>
