<template>
  <div id="uevents">
    <div class="fullWidth atLeft">
      <h2>Events</h2>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uEvents/uEventsWrite">
        <button class="btn">New event</button>
      </router-link>
    </div>
    <div class="fullWidth atLeft">
      <table style="width: 100%;">
        <tr v-if="events.length === 0">
          ・No Events.
        </tr>
        <template v-else>
          <tr v-for="item in events" :key="item.id">
            <td :style="{ borderColor: $store.state.textColor }">・ {{ item.title }}</td>

            <td style="width: 100px" :style="{ borderColor: $store.state.textColor }">
              <button @click="edit(item.id)">Edit</button>
              <button @click="del(item.id)">Delete</button>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'uEvents',
    data: () => ({
      events: [],
    }),
    mounted() {
      this.get();
    },
    methods: {
      async get() {
        const r = await this.$firebase
          .firestore()
          .collection('Events')
          .orderBy('sDate', 'desc')
          .get();

        r.forEach((v) => {
          const { title, href, sDate, eDate } = v.data();
          this.events.push({
            title,
            href,
            id: v.id,
            sDate,
            eDate,
          });
        });
      },
      edit(id) {
        this.$router.push('/uEvents/uEventsWrite/' + id);
      },
      async del(id) {
        if (confirm('本当に削除しますか?')) {
          await this.$firebase
            .firestore()
            .collection('Events')
            .doc(id)
            .delete();

          alert('削除完了');
          this.events = [];
          this.get();
        } else {
          return;
        }
      },
    },
  };
</script>
<style>
  #uevents {
    width: 100%;
  }

  td {
    border-bottom: 1px solid white;
  }
</style>
