<template>
  <div id="uabout">
    <div class="fullWidth atLeft">
      <h2>Edit Copyright Announcement</h2>
    </div>
    <div class="fullWidth text atLeft">
      <textarea
        name="aboutText"
        class="bigText"
        id="aboutText"
        v-model="descA"
        placeholder="Content"
      ></textarea>
    </div>
    <div class="ghost" id="ghostAbout">{{ descA }}</div>

    <div class="fullWidth text atLeft">
      <textarea
        name="aboutText"
        class="bigText"
        id="aboutText"
        v-model="descB"
        placeholder="Content"
      ></textarea>
    </div>
    <div class="fullWidth atLeft">
      <button class="btn" @click="post">Write</button>
    </div>
    <div class="ghost" id="ghostAbout">{{ descB }}</div>
  </div>
</template>
<style>
#uabout {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.atLeft {
  justify-content: left;
  text-align: left;
}
.bigText {
  width: 80%;
  height: 80%;
  font-family: serif;
  padding: 10px;
}
.fullWidth {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.text {
  height: 50%;
}
.btn {
  margin: 10px;
  min-width: 70px;
  height: 50px;
  font-family: serif;
  font-size: 15px;
  border-radius: 8px;
  background-color: rgb(127, 127, 255);
  color: white;
}
</style>
<script>
export default {
  name: "about",
  data: () => ({
    descA: "",
    descB: ""
  }),
  mounted() {
    this.get();
  },
  methods: {
    async post() {
      await this.$firebase
        .firestore()
        .collection("Copyright")
        .doc("announcement")
        .set({
          desc: this.descA,
          descB: this.descB
        });
      alert("修正完了");
      this.$router.push("/uHome");
    },
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection("Copyright")
        .doc("announcement")
        .get();

      this.descA = r.data().desc;
      this.descB = r.data().descB;
    }
  }
};
</script>

<style>
.ghost {
  display: none;
}
</style>