<template>
  <div id="uEventsWrite">
    <div class="fullWidth atLeft">
      <h2>Events - {{ this.$route.params.id ? 'Edit' : 'Make new' }} event</h2>
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        title
      </div>
      <input type="text" class="fullInput" id="title" v-model="title" placeholder="Title" />
    </div>
    <div class="fullWidth atLeft" style="height: fit-content; padding-bottom: 80px;">
      <div class="info">
        desc
      </div>
      <vue-editor v-model="desc" style="width: 500px; height: 200px;"></vue-editor>
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        link
      </div>
      <input type="text" class="fullInput" id="href" v-model="href" placeholder="href" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        start date
      </div>
      <input type="date" style="padding: 0 10px; margin-right: 10px;" id="sDate" v-model="sDate" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">
        end date
      </div>
      <input type="date" style="padding: 0 10px; margin-right: 10px;" id="eDate" v-model="eDate" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info">Image {{ uploadValue === 0 || uploadValue === 100 ? '' : `(${uploadValue}%)` }}</div>
      <input type="file" class="fullInput" accept="image/*" @change="previewImage" />
    </div>
    <div class="fullWidth atLeft">
      <div class="info"></div>
      <div style="width: 300px;">
        <img :src="picture !== null || picture !== '' ? picture : null" id="uploadedImage" />
      </div>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uEvents">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post(id)" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
  import { VueEditor } from 'vue2-editor';

  export default {
    name: 'uEventsWrite',
    components: {
      VueEditor,
    },
    data: () => ({
      title: '',
      desc: '',
      href: '',
      sDate: '',
      eDate: '',
      imageData: null,
      picture: null,
      uploadValue: 0,
    }),
    mounted() {
      this.get(this.$route.params.id);
    },
    methods: {
      async post(id) {
        if (id) {
          if ([this.title, this.sDate, this.eDate].includes('')) {
            alert('Title, Start date and Dnd Date cannot be empty.');
            return false;
          }
          await this.$firebase
            .firestore()
            .collection('Events')
            .doc(id)
            .update({
              title: this.title,
              desc: this.desc,
              href: this.href,
              sDate: this.sDate,
              eDate: this.eDate,
              picture: this.picture ?? '',
            });
          alert('修正完了');
          this.$router.push('/uEvents');
        } else {
          if ([this.title, this.sDate, this.eDate].includes('')) {
            alert('Title and release date cannot be empty.');
            return false;
          }
          await this.$firebase
            .firestore()
            .collection('Events')
            .add({
              title: this.title,
              desc: this.desc,
              href: this.href,
              sDate: this.sDate,
              eDate: this.eDate,
              picture: this.picture ?? '',
              date: new Date(),
            });
          alert('作成完了');
          this.$router.push('/uEvents');
        }
      },

      async get(id) {
        if (id) {
          const snapshot = await this.$firebase
            .firestore()
            .collection('Events')
            .doc(id)
            .get();
          this.title = snapshot.data().title;
          this.desc = snapshot.data().desc;
          this.href = snapshot.data().href;
          this.sDate = snapshot.data().sDate;
          this.eDate = snapshot.data().eDate;
          this.picture = snapshot.data().picture;
          this.id = this.$route.params.id;
        }
      },

      previewImage(e) {
        this.picture = null;
        const rightNow = Date.parse(new Date());
        this.imageData = e.target.files[0];
        const storageRef = this.$firebase
          .storage()
          .ref(`${rightNow + '-' + this.imageData.name}`)
          .put(this.imageData);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.picture = url;
            });
          }
        );
      },
    },
  };
</script>

<style scoped>
  #uEventsWrite {
    width: 100%;
    min-width: 800px;
  }

  #uploadedImage {
    width: 100%;
  }
</style>

<style>
  .info {
    width: 120px;
    height: 54px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
</style>
