<template>
  <div id="utopia">
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'Utopia',
    components: {},
  };
</script>

<style>
  #utopia {
    padding: 40px 0;
    width: 100%;
  }

  /* Mobile */
  @media screen and (max-width: 768px) {
    #utopia {
      padding: 4rem 0;
    }
  }
</style>
