<template>
  <div id="works" class="topSpace">
    <!-- Originals -->
    <div class="works-container">
      <div class="works-title">
        <h2 :style="{ borderColor: $store.state.textColor }">
          <span>original</span>
          <hr />
        </h2>
      </div>
      <div class="works-content">
        <p v-if="originals.length === 0">
          ・No New tracks.
        </p>
        <template v-else>
          <p v-for="item in originals" :key="item.id">
            ・
            <a :href="item.href" target="_blank" rel="noopener noreferrer" :style="{ color: $store.state.textColor }">
              {{ item.title }}
            </a>
          </p>
        </template>
      </div>
    </div>

    <!-- +81 works -->
    <div class="works-container">
      <div class="works-title">
        <h2 :style="{ borderColor: $store.state.textColor }">
          <span>+81 works</span>
          <hr />
        </h2>
      </div>
      <div class="works-content">
        <p v-if="works81.length === 0">
          ・No New tracks.
        </p>
        <template v-else>
          <p v-for="item in works81" :key="item.id">
            ・
            <a :href="item.href" target="_blank" rel="noopener noreferrer" :style="{ color: $store.state.textColor }">
              {{ item.title }}
            </a>
          </p>
        </template>
      </div>
    </div>

    <!-- Remix -->
    <div class="works-container">
      <div class="works-title">
        <h2 :style="{ borderColor: $store.state.textColor }">
          <span>remix</span>
          <hr />
        </h2>
      </div>
      <div class="works-content">
        <p v-if="remix.length === 0">
          ・No New tracks.
        </p>
        <template v-else>
          <p v-for="item in remix" :key="item.id">
            ・
            <a :href="item.href" target="_blank" rel="noopener noreferrer" :style="{ color: $store.state.textColor }">
              {{ item.title }}
            </a>
          </p>
        </template>
      </div>
    </div>

    <!-- Clients -->
    <div class="works-container">
      <div class="works-title">
        <h2 :style="{ borderColor: $store.state.textColor }">
          <span>clients</span>
          <hr />
        </h2>
      </div>
      <div class="works-content">
        <p v-if="clients.length === 0">
          ・No clients.
        </p>
        <template v-else>
          <p v-for="item in clients" :key="item.id">
            ・
            <a :href="item.url" target="_blank" rel="noopener noreferrer" :style="{ color: $store.state.textColor }">
              {{ item.name }} - {{ item.client }}
            </a>
          </p>
        </template>
      </div>
    </div>

    <!-- Labels -->
    <div class="works-container">
      <div class="works-title long">
        <h2 :style="{ borderColor: $store.state.textColor }">
          <span>labels i worked with</span>
          <hr />
        </h2>
      </div>
      <div class="works-content" v-if="labels !== ''" v-html="labels"></div>
      <div class="works-content" v-else>No Labels</div>
    </div>

    <div class="fullWidth">
      <router-link to="/home">
        <span class="Back" :style="{ color: $store.state.textColor }">back</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'works',
    titleTemplate: '%s | aiobahn.net',
    htmlAttrs: {
      lang: 'ja-JP',
    },
    meta: [
      { charset: 'utf-8' },
      // SEO
      { name: 'description', content: 'discography, labels and clients' },
      { name: 'author', content: 'aiobahn, titancube' },
      // SNS
      { property: 'og:title', content: 'works', template: (chunk) => `${chunk}`, vmid: 'og:title' },
      // mobile
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
  name: 'works',
  data: () => ({
    works: [],
    originals: [],
    works81: [],
    remix: [],
    labels: '',
    clients: [],
  }),
  mounted() {
    this.get();

    document
      .querySelector('a')
      .setAttribute('style', `color: ${this.$store.state.textColor};`);
    document.querySelectorAll('hr')
      .forEach((hr) => {
        hr.setAttribute('style', `border-color: ${this.$store.state.textColor};`)
      })
  },
  methods: {
    async get() {
      const works = await this.$firebase
        .firestore()
        .collection('Works')
        .orderBy('rDate', 'desc')
        .get();

      works.forEach((v) => {
        const { title, href, type } = v.data();

        switch (type) {
          default:
          case 1:
            this.originals.push({
              title,
              href,
              id: v.id,
              type,
            })
            break;
          case 2:
            this.remix.push({
              title,
              href,
              id: v.id,
              type,
            })
            break;
          case 3:
            this.works81.push({
              title,
              href,
              id: v.id,
              type,
            })
            break;
        }

      });

      const clients = await this.$firebase
        .firestore()
        .collection('ClientList')
        .orderBy('name', 'asc')
        .get();

      clients.forEach((item) => {
        const { name, client, url } = item.data()
        this.clients.push({
          name,
          client,
          url
        })
      })

      const labels = await this.$firebase
        .firestore()
        .collection('Labels')
        .doc('Content')
        .get();
      this.labels = labels.data().content;
    },
  },
};
</script>

<style>
#works {
  padding: 40px 0;
  width: 100%;
}

.halfWidth {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .halfWidth {
    width: 90%;
  }
}
</style>

<style scoped>
.works-container {
  width: 100%;
  text-align: initial;
  margin: 0 auto 100px auto;
}

.works-title {
  position: relative;
  display: flex;
}

.works-title h2 {
  padding-right: 10px;
  display: flex;
}

.works-title h2 hr {
  flex-grow: 1;
  border-color: inherit;
  border: none;
  border-top: 2px solid;
  margin-left: 1rem;
}

/* .works-title h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 110px;
  flex-grow: 1;
  right: 0;
  height: 25%;
  transform: translate(0, -50%);
  border-top: 2px solid;
  overflow: hidden;
} */

.works-title.long h2:after {
  left: 300px;
}

@media screen and (max-width: 768px) {
  .works-container {
    width: 90%;
  }
}
</style>
