<template>
  <div id="amenu">
    <div class="menuWidth">
      <div class="menuContent">
        <router-link to="/about" :style="{ color: $store.state.textColor }">about</router-link>
      </div>
      <div class="menuContent">
        <router-link to="/works" :style="{ color: $store.state.textColor }">works</router-link>
      </div>
      <div class="menuContent">
        <router-link to="/events" :style="{ color: $store.state.textColor }">events</router-link>
      </div>
      <div class="menuContent">
        <a :href="access" target="blank" :style="{ color: $store.state.textColor }">access</a>
      </div>
      <div class="menuContent">
        <router-link to="/link" :style="{ color: $store.state.textColor }">link</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    access: '',
  }),
  mounted() {
    this.getAccessLink();
  },
  methods: {
    async getAccessLink() {
      const r = await this.$firebase
        .firestore()
        .collection('Look')
        .doc('look')
        .get();

      this.access = r.data().access;
    },
  },
};
</script>

<style>
#amenu {
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  margin-top: 30px;
  line-height: 45px;
}

.menuContent {
  font-size: 25px;
  font-weight: 400;
}

#amenu * {
  line-height: unset;
}

@media screen and (max-width: 768px) {
  #amenu {
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .menuContent {
    width: 50%;
  }

  .menuWidth {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
