<template>
  <div id="uworks">
    <div class="fullWidth atLeft">
      <h2>Works</h2>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uWorks/uWorksWrite">
        <button class="btn">Make new release</button>
      </router-link>
      <router-link to="/uWorks/uWorksLabels">
        <button class="btn">Edit Labels</button>
      </router-link>
    </div>
    <div class="fullWidth atLeft">
      <table>
        <tr v-if="works.length === 0">
          ・No New tracks.
        </tr>
        <tr v-for="work in works" :key="work.id" v-else>
          <td :style="{ borderColor: $store.state.textColor }">
            ・
            <a :href="work.href" target="_blank" rel="noopener noreferrer" :style="{ color: $store.state.textColor }">{{
              work.title
            }}</a>
          </td>

          <td style="width: 100px" :style="{ borderColor: $store.state.textColor }">
            <button @click="edit(work.id)">Edit</button>
            <button @click="del(work.id)">Delete</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uWorks',
  data: () => ({
    works: [],
  }),
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection('Works')
        .orderBy('rDate', 'desc')
        .get();

      r.forEach((v) => {
        const { title, href } = v.data();
        this.works.push({
          title,
          href,
          id: v.id,
        });
      });
    },
    edit(id) {
      this.$router.push('/uWorks/uWorksWrite/' + id);
    },
    async del(id) {
      if (confirm('本当に削除しますか?')) {
        await this.$firebase
          .firestore()
          .collection('Works')
          .doc(id)
          .delete();

        alert('削除完了');
        this.works = [];
        this.get();
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
#uworks {
  width: 100%;
}

td {
  border-bottom: 1px solid white;
}
</style>
