import Vue from "vue";
import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const db = firebase.default;

const firebaseConfig = {
  apiKey: "AIzaSyBzBsg7VpKZm8txRWI3ZgbYevMp5jQ4_To",
  authDomain: "aioweb-45ddb.firebaseapp.com",
  databaseURL: "https://aioweb-45ddb.firebaseio.com",
  projectId: "aioweb-45ddb",
  storageBucket: "aioweb-45ddb.appspot.com",
  messagingSenderId: "71544283688",
  appId: "1:71544283688:web:44ae7dfbb16dfc33540b1d",
  measurementId: "G-0Y48GY522Z",
};

// Initialize Firebase
db.initializeApp(firebaseConfig);

Vue.prototype.$firebase = db;
