<template>
  <div id="ulink">
    <div class="fullWidth atLeft">
      <h2>Link</h2>
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uLink/uLinkWrite">
        <button class="btn">Write New Link</button>
      </router-link>
      <router-link to="/uLink/uAnnouncement">
        <button class="btn">Edit Announcement</button>
      </router-link>
    </div>
    <spinner v-if="isReady == false" />
    <div v-if="isReady" v-html="content"></div>
    <div v-if="isReady" class="fullWidth">
      <table class="linkTable bigTable" :style="{ borderColor: $store.state.textColor }">
        <!-- No Banner -->
        <tr v-if="items == ''">
          No banner
        </tr>
        <!-- Banner exists -->
        <tr class="linkTable" v-for="item in items" :key="item.id">
          <td class="linkTable tbBanner" :style="{ borderColor: $store.state.textColor }">
            <a
              :href="item.href"
              target="_blank"
              rel="noopener noreferrer"
              v-if="item.title && !item.banner"
              class="noDeco"
              >{{ item.title }}</a
            >
            <a :href="item.href" target="_blank" rel="noopener noreferrer" v-if="item.banner">
              <img :src="item.banner" alt class="noFlex" v-if="item.banner" />
            </a>
          </td>
          <td class="linkTable tbDesc" :style="{ borderColor: $store.state.textColor }">
            {{ item.desc }}
            <br />
            <button @click="edit(item.id)">Edit</button>
            <button @click="del(item.id)">Delete</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
  import Spinner from '@/components/Spinner.vue';

  export default {
    name: 'uLink',
    data: () => ({
      items: [],
      content: '',
      isReady: false,
    }),
    components: {
      Spinner,
    },
    mounted() {
      this.get();
      this.aioget();
    },
    methods: {
      edit(id) {
        this.$router.push('/uLink/uLinkWrite/' + id);
      },
      async get() {
        const snapshot = await this.$firebase
          .firestore()
          .collection('Link')
          .orderBy('title')
          .get();
        snapshot.forEach((v) => {
          const { title, banner, href, desc } = v.data();
          this.items.push({
            title,
            banner,
            href,
            desc,
            id: v.id,
          });
        });
      },
      async aioget() {
        const r = await this.$firebase
          .firestore()
          .collection('Aiosays')
          .doc('something')
          .get();

        this.content = r.data().content;
        this.isReady = true;
      },
      async del(id) {
        if (confirm('本当に削除しますか?')) {
          await this.$firebase
            .firestore()
            .collection('Link')
            .doc(id)
            .delete();

          alert('削除完了');
          this.items = [];
          this.get();
        } else {
          return;
        }
      },
      newCheck: function(e) {
        if (this.items[0].id == e) {
          return true;
        }
      },
    },
  };
</script>
<style></style>
