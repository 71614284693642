<template>
  <div id="link" class="topSpace">
    <div v-html="aioSays"></div>
    <div class="lkBottom fullWidth">
      <table class="linkTable bigTable" :style="{ borderColor: $store.state.textColor }">
        <!-- Repeat this area -->
        <!-- No Banner -->
        <tr v-if="items == ''">
          No banner
        </tr>
        <!-- Banner exists -->
        <tr class="linkTable" v-for="item in items" :key="item.id">
          <td class="linkTable tbBanner" :style="{ borderColor: $store.state.textColor }">
            <a :href="item.href" target="_blank" rel="noopener noreferrer" v-if="item.title && !item.banner" class>{{
              item.title
            }}</a>
            <a :href="item.href" target="_blank" rel="noopener noreferrer" v-if="item.banner">
              <img :src="item.banner" alt class="noFlex" v-if="item.banner" />
            </a>
            <div class="mobileDesc">{{ item.desc }}</div>
          </td>
          <td class="linkTable tbDesc" :style="{ borderColor: $store.state.textColor }">
            {{ item.desc }}
          </td>
        </tr>
        <!-- Repeat this area -->
      </table>
    </div>
    <div class="fullWidth">
      <router-link to="/home">
        <span class="Back" :style="{ color: $store.state.textColor }">back</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'link',
    titleTemplate: '%s | aiobahn.net',
    htmlAttrs: {
      lang: 'ja-JP',
    },
    meta: [
      { charset: 'utf-8' },
      // SEO
      { name: 'description', content: 'recommendations' },
      { name: 'author', content: 'aiobahn, titancube' },
      // SNS
      { property: 'og:title', content: 'link', template: (chunk) => `${chunk}`, vmid: 'og:title' },
      // mobile
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
  name: 'Link',
  data: () => ({
    items: [],
    aioSays: ``,
  }),
  mounted() {
    this.get();
    this.aioget();
  },
  methods: {
    async get() {
      const snapshot = await this.$firebase
        .firestore()
        .collection('Link')
        .orderBy('title')
        .get();
      snapshot.forEach((v) => {
        const { title, banner, href, desc } = v.data();
        this.items.push({
          title,
          banner,
          href,
          desc,
          id: v.id,
        });
      });
    },
    async aioget() {
      const r = await this.$firebase
        .firestore()
        .collection('Aiosays')
        .doc('something')
        .get();
      this.aioSays = r.data().content;
    },
  },
};
</script>

<style>
#link {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.bigTable {
  width: 80%;
  height: 70px;
}

table.linkTable {
  border: 5px outset white;
}

tr.linkTable,
td.linkTable {
  border: 1px solid white;
  text-align: center;
}

.tbBanner {
  min-width: 220px;
  min-height: 60px;
  padding: 10px;
}

.tbDesc {
  text-align: left;
  width: 60%;
  word-break: break-all;
  padding: 10px;
}

.noFlex {
  display: initial;
}

.noDeco {
  color: black;
}

.mobileDesc {
  display: none;
}

@media screen and (max-width: 768px) {
  .lkTop {
    width: 90%;
  }

  .tbDesc {
    display: none;
  }

  .mobileDesc {
    margin: 5px 0 5px 0;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}</style>
