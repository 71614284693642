<template>
  <div id="login">
    <div class="lgLeft">
      <div class="lgLeftSmall">ID</div>
      <div class="lgLeftSmall">PW</div>
    </div>
    <div class="lgRight">
      <div class="lgRightSmall">
        <input id="uId" class="bigInput" type="text" v-model="userId" />
      </div>

      <div class="lgRightSmall">
        <input id="uPw" class="bigInput" type="password" v-model="userPw" />
      </div>
      <div class="ghost">{{userId}}</div>
      <div class="ghost">{{userPw}}</div>
    </div>
    <div class="buttonBox">
      <button @click="login" class="lgButton">Login</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data: () => ({
    userId: "",
    userPw: ""
  }),
  mounted() {
    this.getCurrentUser();
  },
  methods: {
    async login() {
      await this.$firebase
        .auth()
        .setPersistence(this.$firebase.auth.Auth.Persistence.SESSION);
      await this.$firebase
        .auth()
        .signInWithEmailAndPassword(this.userId, this.userPw);

      await this.$firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          sessionStorage.setItem("email", user.email);
          alert("Logged in as: " + user.email);
          return;
        } else {
          alert("Invalid User");
          return false;
        }
      });

      this.$router.push("/uHome");
    },
    async getCurrentUser() {
      if (sessionStorage.getItem("email")) {
        this.$router.push("/uHome");
      }
    }
  }
};
</script>

<style>
#login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.lgLeft {
  font-size: 30px;
  font-style: italic;
  width: 50px;
  line-height: 50px;
}
.lgRight {
  width: 250px;
}
.bigInput {
  width: 200px;
  height: 20px;
  padding: 5px;
  border-style: inset;
  border-width: 3px;
  background-color: gray;
  color: white;
  font-size: 15px;
  font-style: italic;
  font-family: serif;
}
.lgLeftSmall {
  height: 50px;
  line-height: 50px;
}
.lgRightSmall {
  height: 50px;
  line-height: 50px;
}
.lgButton {
  margin: 10px;
  min-width: 70px;
  height: 50px;
  font-family: serif;
  font-size: 15px;
  border-radius: 8px;
  background-color: rgb(127, 127, 255);
  color: white;
}
.buttonBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>