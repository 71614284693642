import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    textColor: '',
    backgroundImage: '',
    introImage: '',
  },
  mutations: {
    changeTextColor(state, p) {
      state.textColor = p;
    },
    changeBackground(state, p) {
      state.backgroundImage = p;
    },
    changeIntro(state, p) {
      state.introImage = p;
    },
  },
  actions: {
    getTextColor({ commit }, p) {
      commit('changeTextColor', p);
    },
  },
  modules: {},
});
