<template>
  <div id="counter">
    <div class="counter-horizontal">
      <span>
        あなたは

      </span>
      <!-- FC2カウンター ここから -->
      <div :ref="counter">
        <component is="script" src="//counter1.fc2.com/counter.php?id=34750141&main=1" async />
        <noscript><img src="//counter1.fc2.com/counter_img.php?id=34750141&main=1" /></noscript>
      </div>
      <!-- FC2カウンター ここまで -->

      <span>

        人目のお客様です
      </span>
    </div>
    <div>
      <div style="font-size:12px;text-align:center;">
        <a href="https://www.tenki-yoho.com/cities/63.html" target="_blank">
          <img src="https://www.tenki-yoho.com/blogparts/img.php?63,acrweb" alt="東京の天気予報" width="100" height="100" />
        </a>
        <br>
        -
        <a href="http://www.tenki-yoho.com/" target=_blank>天気予報コム</a>
        -
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Counter",
  data: () => ({
    cNumber: 0
  }),
  mounted() {
    this.get();
    let counterScript = document.createElement('script')
    counterScript.setAttribute('src', 'https://counter1.fc2.com/counter.php?id=34750141&main=1')
    this.$refs.counter.appendChild(counterScript)
  },
  updated() {
    this.count();
  },
  methods: {
    async count() {
      await this.$firebase
        .firestore()
        .collection("Counter")
        .doc("clock")
        .set({
          number: parseInt(this.cNumber)
        });
    },
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection("Counter")
        .doc("clock")
        .get();

      this.cNumber = (r.data().number + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 7,
        useGrouping: false
      });
    }
  }
};
</script>

<style>
.counter {
  background-color: black;
  color: yellow;
  /* font-weight: bold; */
}

#counter {
  margin: 15px 0 15px 0;
}

.counter-horizontal {
  display: flex;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 640px) {

  .counter-horizontal {
    flex-direction: column;
  }

  .counter-horizontal>span {
    margin-bottom: 1rem;
  }
}
</style>