<template>
  <div id="app" :style="{ color: $store.state.textColor }">
    <div id="bigContainer" v-if="$router.currentRoute.path !== '/'">
      <navbar />
      <div id="container">
        <router-view />
      </div>
    </div>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar';
export default {
  name: 'App',
  data: () => ({
  }),
  components: {
    Navbar,
  },
  async beforeCreate() {

    // (function (d) {
    //   var config = {
    //     kitId: 'fhs8rui',
    //     async: true
    //   },
    //     h = d.documentElement, t = setTimeout(function () { h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive"; }, config.scriptTimeout), tk = d.createElement("script"), f = false, s = d.getElementsByTagName("script")[0], a; h.className += " wf-loading"; tk.src = 'https://use.typekit.net/' + config.kitId + '.js'; tk.async = true; tk.onload = tk.onreadystatechange = function () { a = this.readyState; if (f || a && a != "complete" && a != "loaded") return; f = true; clearTimeout(t); try { Typekit.load(config) } catch (e) { } }; s.parentNode.insertBefore(tk, s)
    // })(document);

  },
  async mounted() {
    await this.getColor()
    const styleElement = document.createElement('style')
    document.head.appendChild(styleElement)
    const sheet = styleElement.sheet
    sheet.insertRule(`a { color: ${this.$store.state.textColor};}`)

    if (this.$route.fullPath === '/') {
      document
        .querySelector('html')
        .setAttribute('style', `background-image: none;`);
    } else {
      document
        .querySelector('html')
        .setAttribute('style', `background-image: url(${this.$store.state.backgroundImage});`);
    }

  },
  methods: {
    async getColor() {
      const r = await this.$firebase
        .firestore()
        .collection('Look')
        .doc('look')
        .get();

      this.$store.commit('changeTextColor', r.data().textColor);
      this.$store.commit('changeBackground', r.data().background);
      this.$store.commit('changeIntro', r.data().introImage);
    },
  },
  computed: {
    backgroundImage: function () {
      return this.$route.fullPath === '/' ? '' :
        this.$store.state.backgroundImage;
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.path === '/') {
        document
          .querySelector('html')
          .setAttribute('style', `background-image: none;`);
      } else {
        document
          .querySelector('html')
          .setAttribute('style', `background-image: url(${this.$store.state.backgroundImage});`);

      }

    }
  }
};
</script>

<style>
/* @font-face {
  font-family: "Kosugi Maru";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url('~@/assets/font/KosugiMaru-Regular.ttf');
} */

@font-face {
  font-family: "Haas Grotesk Text Web";
  font-weight: 400;
  font-style: normal;
  src: url('~@/assets/font/NeueHaasGroteskText-55Roman-Web.woff2') format('woff2'),
    url('~@/assets/font/NeueHaasGroteskText-55Roman-Web.woff') format('woff');
}

/* .NeueHaasGroteskText-55Roman-Web {
	font-family: 'Haas Grotesk Text Web';
	font-weight: 400;
	font-style: normal;
	} */


html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: white;

  font-family: "Haas Grotesk Text Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "Haas Grotesk Text Web", sans-serif;
  line-height: 1.3rem;
}

h2 {
  font-weight: 400;
  width: 100%
}

@media (prefers-color-scheme; dark) {

  html,
  body {
    background-color: white;
  }
}

body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

a {
  display: contents;
}

p {
  margin: 0;
}

#app {
  display: flex;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
}

#bigContainer {
  top: 40px;
  display: flex;
  /* max-width: 1024px; */
  width: 1024px;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
}

#container {
  top: 40px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  display: flex;
}

img {
  /* display: flex; */
  height: fit-content;
  width: fit-content;
}

#imgIndex {
  height: fit-content;
  display: flex;
  align-content: center;
  vertical-align: middle;
  align-self: center;
}

.fullWidth {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
}

pre {
  font: inherit;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.topMenu {
  min-width: 40px;
  min-height: 30px;
  margin: 10px;
}

.Back {
  font-weight: 400;
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 20px;
}

hr.solid {
  width: 100%;
  border-top: 1px solid white;
  height: 0px;
  margin: 20px 0 20px 0;
}

div {
  white-space: pre-line;
}

.mail {
  margin: 0;
  margin-top: 0;
}

.block {
  display: block;
}

.textWidth {
  width: fit-content;
  line-height: 20px;
}

.topSpace {
  padding: 40px 0;
  margin: auto 0;
  width: inherit;
}

@media screen and (max-width: 768px) {
  .topSpace {
    margin-top: 20px;
  }
}
</style>
