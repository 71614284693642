<template>
  <div id="about" class="topSpace">
    <div v-if="picture" class="abFullWidth">
      <img :src="picture" alt />
    </div>
    <div class="abFullWidth abText" v-html="desc"></div>
    <div class="abFullWidth">
      <router-link to="/home">
        <span class="Back" :style="{ color: $store.state.textColor }">back</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'About',
    titleTemplate: '%s | aiobahn.net',
    htmlAttrs: {
      lang: 'ja-JP',
    },
    meta: [
      { charset: 'utf-8' },
      // SEO
      { name: 'description', content: 'info about aiobahn' },
      { name: 'author', content: 'aiobahn, titancube' },
      // SNS
      { property: 'og:title', content: 'About', template: (chunk) => `${chunk}`, vmid: 'og:title' },
      // mobile
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
  name: 'About',
  data: () => ({
    desc: '',
    picture: '',
  }),
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection('About')
        .doc('4VZ5rTVkuJ3lPuOgNCzh')
        .get();

      this.desc = r.data().desc;
      this.picture = r.data().picture;
    },
  },
};
</script>

<style>
#about {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.abFullWidth {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.abText {
  display: block;
}

@media screen and (max-width: 768px) {
  .abFullWidth {
    width: 90%;
  }

  .abFullWidth>img {
    max-width: 100%;
    height: auto;
  }
}
</style>
