<template>
  <div id="unewswrite">
    <div class="fullWidth atLeft">
      <h2>Link - {{ this.$route.params.id ? "Edit" : "Write New" }} Link</h2>
    </div>
    <div class="ghost">{{ title }}</div>
    <div class="ghost">{{ href }}</div>
    <div class="ghost">{{ desc }}</div>
    <div class="fullWidth atLeft">
      <input type="text" class="fullInput" id="title" v-model="title" placeholder="Title" />
    </div>
    <div class="fullWidth atLeft">
      <div class="bannerPreview">
        <img :src="picture" alt style="margin:auto;" />
      </div>
      <div class="fileBox">
        <input type="file" class="halfInput" id="banner" @change="previewImage" />
      </div>

      <div class="fileProg">{{ uploadValue.toFixed() + "%" }}</div>
    </div>
    <div class="fullWidth atLeft">
      <input type="text" class="fullInput" id="href" v-model="href" placeholder="Link" />
    </div>
    <div class="fullWidth atLeft">
      <input type="text" class="fullInput" id="desc" v-model="desc" placeholder="Description" />
    </div>
    <div class="fullWidth atLeft">
      <router-link to="/uLink">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post(id)" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    title: "",
    href: "",
    desc: "",
    id: "",
    imageData: null,
    picture: null,
    uploadValue: 0
  }),
  mounted() {
    this.get(this.$route.params.id);
  },
  methods: {
    async post(id) {
      if (id) {
        if (this.title == "" || this.href == "") {
          alert("Title and Link cannot be empty.");
          return false;
        }
        await this.$firebase
          .firestore()
          .collection("Link")
          .doc(id)
          .update({
            title: this.title,
            href: this.href,
            banner: this.picture,
            desc: this.desc,
            date: new Date()
          });

        alert("修正完了");
        this.$router.push("/uLink");
      } else {
        if (this.content == "") {
          alert("Title and Content cannot be empty.");
          return false;
        }
        await this.$firebase
          .firestore()
          .collection("Link")
          .add({
            title: this.title,
            banner: this.picture,
            href: this.href,
            desc: this.desc,
            date: new Date()
          });
        alert("作成完了");
        this.$router.push("/uLink");
      }
    },

    async get(id) {
      if (id) {
        const snapshot = await this.$firebase
          .firestore()
          .collection("Link")
          .doc(id)
          .get();
        this.title = snapshot.data().title;
        this.picture = snapshot.data().banner;
        this.href = snapshot.data().href;
        this.desc = snapshot.data().desc;
        this.id = this.$route.params.id;
      }
    },

    previewImage(e) {
      this.uploadValue = 0;
      this.picture = null;
      const rightNow = Date.parse(new Date());
      this.imageData = e.target.files[0];
      const storageRef = this.$firebase
        .storage()
        .ref(`${rightNow + "-" + this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.picture = url;
          });
        }
      );
    }
  }
};
</script>

<style>
#unewswrite {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.fullInput {
  font-family: serif;
  width: 40%;
  height: 30px;
  line-height: 30px;
  padding: 10px;
}

.halfInput {
  font-family: serif;
  padding: 0 0 0 10px;
}

.fileBox {
  height: 60px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.fileProg {
  height: 60px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-style: italic;
  font-weight: 400;
  color: blue;
}

.bannerPreview {
  display: flex;
  width: 220px;
  height: 60px;
  justify-content: center;
  border: 1px solid blue;
}
</style>