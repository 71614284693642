<template>
  <div id="events" class="topSpace">
    <div class="event-half">
      <div style="width: 100%;">
        <img :src="picture" alt class="poster" style="height: auto;" />
      </div>
    </div>
    <div class="event-half">
      <div class="event-detail">
        {{ title }}
      </div>
      <div class="event-detail" style=" text-decoration: underline; ">
        <a :href="href" target="_blank" rel="noopener noreferrer">
          event link
          <img
            :src="`https://img.icons8.com/material-outlined/20/${$store.state.textColor.slice(1)}/link--v1.png`"
            style="vertical-align:middle;"
          />
        </a>
      </div>
      <div class="event-detail">
        <div>Starts at: {{ timeParser(timeStruct(sDate)) }}</div>
        <div>Ends at: {{ timeParser(timeStruct(eDate)) }}</div>
      </div>
      <div class="event-detail">
        <div v-html="desc"></div>
      </div>
      <div class="event-detail">
        <router-link to="/events">
          <span class="Back" :style="{ color: $store.state.textColor }">back</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { format } from 'date-fns';

  export default {
    name: 'Event',
    data: () => ({
      title: '',
      href: '',
      desc: '',
      picture: '',
      sDate: '',
      eDate: '',
    }),
    mounted() {
      this.get();
    },
    methods: {
      async get() {
        const r = await this.$firebase
          .firestore()
          .collection('Events')
          .doc(this.$route.params.id)
          .get();

        this.title = r.data().title;
        this.desc = r.data().desc;
        this.href = r.data().href;
        this.picture = r.data().picture;
        this.sDate = r.data().sDate;
        this.eDate = r.data().eDate;
      },

      timeStruct(date) {
        return new Date(date);
      },

      timeParser(time) {
        return format(time, 'yyyy/MM/dd');
      },
    },
  };
</script>

<style>
  #events {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
  }

  .abFullWidth {
    display: flex;
    width: 100%;
    height: fit-content;
    /* justify-content: center; */
    margin: 20px 0 20px 0;
  }

  .abText {
    display: block;
  }

  .poster {
    width: 100%;
  }

  .event-half {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    display: block;
  }

  .event-detail {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    .abFullWidth {
      width: 90%;
    }

    .abFullWidth > img {
      max-width: 100%;
      height: auto;
    }

    .event-half {
      width: 100%;
      padding: 10px 10px 0 10px;
    }
  }
</style>
