<template>
  <div id="unewswrite">
    <div class="fullWidth atLeft" id="newsTitle">
      <h2>News - {{ this.$route.params.id ? 'Edit' : 'Write New' }} Article</h2>
    </div>
    <div class="fullWidth atLeft" id="newsContent">
      <textarea
        name="newsText"
        class="bigText"
        id="newsText"
        cols="30"
        v-model="content"
        rows="10"
        placeholder="Content"
      ></textarea>
    </div>
    <div class="fullWidth atLeft" id="newsLink">
      <input type="text" class="fullInput" v-model="link" placeholder="LINK" />
    </div>
    <div class="fullWidth atLeft" id="newsButtons">
      <router-link to="/uHome">
        <button class="btn">Cancel</button>
      </router-link>
      <button @click="post(id)" class="btn">Write</button>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
      content: '',
      id: '',
      link: '',
    }),
    mounted() {
      this.get(this.$route.params.id);
    },
    methods: {
      async post(id) {
        if (id) {
          if (this.content == '') {
            alert('Content cannot be empty.');
            return false;
          }
          await this.$firebase
            .firestore()
            .collection('News')
            .doc(id)
            .set(
              {
                content: this.content,
                link: this.link,
              },
              { merge: true }
            );
          alert('修正完了');
        } else {
          if (this.content == '') {
            alert('Title and Content cannot be empty.');
            return false;
          }
          await this.$firebase
            .firestore()
            .collection('News')
            .add({
              content: this.content,
              link: this.link,
              date: new Date(),
            });
          alert('作成完了');
        }
        this.$router.push('/uHome');
      },
      async get(id) {
        if (id) {
          const snapshot = await this.$firebase
            .firestore()
            .collection('News')
            .doc(id)
            .get();
          this.content = snapshot.data().content;
          this.id = this.$route.params.id;
          this.link = snapshot.data().link;
        }
      },
    },
  };
</script>

<style>
  #unewswrite {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .fullInput {
    font-family: serif;
    width: 80%;
    height: 30px;
    line-height: 30px;
    padding: 10px;
  }

  /* Mobile */
  @media screen and (max-width: 768px) {
    #unewswrite {
      justify-content: center;
    }

    #newsTitle > h2 {
      width: 100%;
      text-align: center;
    }

    #newsContent {
      display: flex;
      justify-content: center;
    }

    #newsLink {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    #newsLink > .fullInput {
      width: 80%;
    }

    #newsButtons {
      display: flex;
      width: 80%;
      justify-content: flex-end;
    }
  }
</style>
