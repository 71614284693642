import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About';
import Works from '../views/Works';
import Link from '../views/Link';
import Login from '../views/Login';
import uHome from '../views/uHome';
import uAbout from '../views/uAbout';
import uLink from '../views/uLink';
import uWorks from '../views/uWorks';
import uLook from '../views/uLook';
import Utopia from '../views/Utopia';
import News from '../components/News';
import uNews from '../components/uNews';
import uNewsWrite from '../views/uNewsWrite';
import uLinkWrite from '../views/uLinkWrite';
import uAnnouncement from '../views/uAnnouncement';
import uWorksWrite from '../views/uWorksWrite';
import uCopyright from '../views/uCopyright';
import uProfileWrite from '../views/uProfileWrite';
import uLogoWrite from '../views/uLogoWrite';
import uWorksClients from '../views/uWorksClients';
import uWorksLabels from '../views/uWorksLabels';
import Events from '../views/Events';
import EventsRead from '../views/EventsRead';
import uEvents from '../views/uEvents';
import uEventsWrite from '../views/uEventsWrite';
import uClients from '../views/uClients';
import uClientsWrite from '../views/uClientsWrite';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'intro',
    component: () => import('../views/intro.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [{ path: '', name: 'news', component: News }],
  },
  { path: '/about', name: 'about', component: About },
  { path: '/works', name: 'works', component: Works },
  { path: '/link', name: 'link', component: Link },
  { path: '/events', name: 'events', component: Events },
  { path: '/events/:id?', component: EventsRead },
  {
    path: '/utopia',
    name: 'utopia',
    component: Utopia,
    children: [
      { path: '', name: 'login', component: Login },
      {
        path: '/uhome',
        name: 'uhome',
        component: uHome,
        children: [
          { path: '', component: uNews },
          { path: '/uabout', component: uAbout },
          { path: '/ulink', component: uLink },
          { path: '/uworks', component: uWorks },
          { path: '/uclients', component: uClients },
          { path: '/uevents', component: uEvents },
          { path: '/ulook', component: uLook },
          { path: '/uhome/unewsWrite/:id?', name: 'unewsWrite', component: uNewsWrite },
          { path: '/ulink/ulinkWrite/:id?', name: 'ulinkWrite', component: uLinkWrite },
          { path: '/ulink/uannouncement', name: 'uannouncement', component: uAnnouncement },
          { path: '/uclients/uclientsWrite/:id?', name: 'uclientsWrite', component: uClientsWrite },
          { path: '/uevents/ueventsWrite/:id?', name: 'ueventsWrite', component: uEventsWrite },
          { path: '/uworks/uworksWrite/:id?', name: 'uworksWrite', component: uWorksWrite },
          { path: '/uworks/uworksclients', name: 'uworksclients', component: uWorksClients },
          { path: '/uworks/uworkslabels', name: 'uworkslabels', component: uWorksLabels },
          { path: '/uhome/ucopyright', name: 'ucopyright', component: uCopyright },
          { path: '/uhome/uprofilewrite', name: 'uprofilewrite', component: uProfileWrite },
          { path: '/uhome/ulogowrite', name: 'ulogowrite', component: uLogoWrite },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
