<template>
  <div id="jingle">
    <audio
      id="jingleAudio"
      ref="audio"
      :src="audio"
      style="display: none"
    ></audio>
  </div>
</template>

<script>
export default {
  name: "jingle",
  data: () => ({
    audio: null,
  }),
  mounted() {
    this.get();
    this.$refs.audio.volume = "0.2";
    this.$refs.audio.play();
  },
  methods: {
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection("Look")
        .doc("look")
        .get();
      this.audio = r.data().audio;
    },
  },
};
</script>