<template>
  <div id="events" class="topSpace">
    <!-- upcoming events -->
    <div class="works-container">
      <div class="works-title long">
        <h2 :style="{ borderColor: $store.state.textColor }">
          <span>upcoming events</span>
          <hr />
        </h2>
      </div>
      <div class="works-content">
        <p v-if="eventsUpcoming.length === 0">
          ・No Events.
        </p>
        <template v-else>
          <p v-for="item in eventsUpcoming" :key="item.id">
            ・
            <router-link :to="`/events/${item.id}`">
              {{ `${item.title} ${eventDate(item.sDate, item.eDate)}` }}
            </router-link>
          </p>
        </template>
      </div>
    </div>

    <!-- past events -->
    <div class="works-container">
      <div class="works-title long">
        <h2 :style="{ borderColor: $store.state.textColor }">
          <span>past events</span>
          <hr />
        </h2>
      </div>
      <div class="works-content">
        <p v-if="eventsPast.length === 0">
          ・No Events.
        </p>
        <template v-else>
          <details v-for="year in years" :key="year" class="year">
            <summary>{{ year }}</summary>
            <p v-for="item in eventsPast[year]" :key="item.id">
              ・
              <router-link :to="`/events/${item.id}`">
                {{ `${item.title} ${eventDate(item.sDate, item.eDate)}` }}
              </router-link>
            </p>
          </details>
        </template>
      </div>
    </div>

    <div class="fullWidth">
      <router-link to="/home">
        <span class="Back" :style="{ color: $store.state.textColor }">back</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  metaInfo: {
    title: 'events',
    titleTemplate: '%s | aiobahn.net',
    htmlAttrs: {
      lang: 'ja-JP',
    },
    meta: [
      { charset: 'utf-8' },
      // SEO
      { name: 'description', content: 'upcoming, past events' },
      { name: 'author', content: 'aiobahn, titancube' },
      // SNS
      { property: 'og:title', content: 'events', template: (chunk) => `${chunk}`, vmid: 'og:title' },
      // mobile
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
  name: 'events',
  data: () => ({
    eventsUpcoming: [],
    eventsPast: {},
    years: []
  }),
  mounted() {
    this.get();

    document.querySelectorAll('hr')
      .forEach((hr) => {
        hr.setAttribute('style', `border-color: ${this.$store.state.textColor};`)
      })
  },
  methods: {
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection('Events')
        .orderBy('sDate', 'desc')
        .get();

      const years = new Set();

      r.forEach((event) => {
        const { sDate } = event.data()
        const year = new Date(sDate).getFullYear();
        years.add(year);
      })

      years.forEach((year) => {
        this.eventsPast[year] = []
        this.years.push(year)
      })

      r.forEach((v) => {
        const { title, href, sDate, eDate, date } = v.data();
        const year = new Date(sDate).getFullYear();
        new Date(eDate) > new Date()
          ? this.eventsUpcoming.push({
            title,
            href,
            id: v.id,
            sDate,
            eDate,
            date: new Date(date.seconds * 1000),
          })
          : this.eventsPast[year].push({
            title,
            href,
            id: v.id,
            sDate,
            eDate,
            date: new Date(date.seconds * 1000),
          });
      });

      const d = await this.$firebase
        .firestore()
        .collection('Clients')
        .doc('Content')
        .get();
      this.content = d.data().content;
    },

    timeStruct(date) {
      return new Date(date);
    },

    timeParser(time) {
      return format(time, 'yy/MM/dd');
    },

    eventDate(sDate, eDate) {
      return sDate === eDate
        ? `('${this.timeParser(this.timeStruct(sDate))})`
        : `('${this.timeParser(this.timeStruct(sDate))} ~ '${this.timeParser(this.timeStruct(eDate))})`;
    },
  },
};
</script>

<style scoped>
#events {
  padding: 40px 0;
  width: 100%;
}

.works-container {
  width: 100%;
  text-align: initial;
  margin: 0 auto 100px auto;
}

.works-title {
  position: relative;
  display: flex;
}

.works-title h2 {
  padding-right: 10px;
  display: flex;
}

.works-title h2 hr {
  flex-grow: 1;
  border: none;
  border-top: 2px solid;
  margin-left: 1rem;
}

/* .works-title h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 110px;
  right: 0;
  height: 25%;
  transform: translate(0, -50%);
  border-top: 2px solid;
  overflow: hidden;
} */

.works-title.long h2:after {
  left: 300px;
}

.year summary {
  cursor: pointer;
  padding: 1rem 0;
  font-size: large;
  /* font-weight: bold; */
}

@media screen and (max-width: 768px) {
  .works-container {
    width: 90%;
  }
}
</style>
