<template>
  <div id="unews">
    <div class="fullWidth atLeft" id="areaTitle">
      <h2>News</h2>
    </div>
    <div class="fullWidth atLeft" id="buttonArea">
      <router-link to="/uHome/uNewsWrite">
        <button class="btn">New</button>
      </router-link>
      <router-link to="/uHome/uCopyright">
        <button class="btn">Edit Copyright</button>
      </router-link>
      <router-link to="/uHome/uProfileWrite">
        <button class="btn">Profile</button>
      </router-link>
      <router-link to="/uHome/uLogoWrite">
        <button class="btn">Logo</button>
      </router-link>
    </div>
    <div class="newsBottom fullWidth">
      <div class="newsLeft atLeft" style="width: 100%">
        <table style="width: 100%">
          <!-- Make this recursive -->
          <tr v-if="items == ''">
            ・新しいニュースがありません。
            <br />새 소식이 없습니다.
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td class="utTitle" :style="{ borderColor: $store.state.textColor }">
              ・
              <p style="display: inline" v-if="!item.link">
                {{ item.content }}
              </p>
              <a v-else :href="item.link" target="_blank">{{ item.content }} {{ constructDate(item.date) }}</a>

              <img src="../assets/image/new01.gif" alt class="newFlag" v-if="newCheck(item.id)" />
            </td>
            <td class="utTitle utButtons" :style="{ borderColor: $store.state.textColor }">
              <button class="btn-inline btn-edit" @click="edit(item.id)">EDIT</button>
              <button class="btn-inline btn-delete" @click="del(item.id)">DEL</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { format } from 'date-fns';

  export default {
    name: 'unews',
    data: () => ({
      items: [],
    }),
    mounted() {
      this.get();
    },
    methods: {
      edit(id) {
        this.$router.push('/uHome/uNewsWrite/' + id);
      },
      async get() {
        setTimeout(1000);
        const snapshot = await this.$firebase
          .firestore()
          .collection('News')
          .orderBy('date', 'desc')
          .get();
        snapshot.forEach((v) => {
          const { content, link, date } = v.data();
          this.items.push({
            content,
            id: v.id,
            link,
            date: new Date(date.seconds * 1000),
          });
        });
      },
      async del(id) {
        if (confirm('本当に削除しますか?')) {
          await this.$firebase
            .firestore()
            .collection('News')
            .doc(id)
            .delete();

          alert('削除完了');
          this.items = [];
          this.get();
        } else {
          return;
        }
      },
      newCheck: function(e) {
        if (this.items[0].id == e) {
          return true;
        }
      },
      constructDate(date) {
        return `('${format(date, 'yy/MM/dd')})`;
      },
    },
  };
</script>

<style>
  #unews {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .newsBottom {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .tFixed {
    width: 301px;
  }

  .newsRight {
    width: 25%;
  }

  .utTitle {
    min-width: 100px;
    border-bottom: 1px solid white;
  }

  /* Mobile */
  @media screen and (max-width: 768px) {
    #areaTitle > h2 {
      width: 100%;
      text-align: center;
    }

    #buttonArea {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      width: 100%;
    }

    #buttonArea .btn {
      margin: 0;
    }

    .btn-inline {
      width: 50%;
      outline: none;
      border: 0;
      display: inline-block;
      color: white;
      padding: 1rem 0;
    }

    .btn-edit {
      background-color: #b3b3b3;
    }

    .btn-delete {
      background-color: #bc2a2a;
    }
  }
</style>
