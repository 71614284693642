<template>
  <div id="navbar" class="navbar topbar">
    <nav class="topbar" :style="{ backgroundColor: headerColor }">
      <div class="topMenu">
        <router-link to="/home">home</router-link>
      </div>
      <div class="topMenu">
        <router-link to="/about">about</router-link>
      </div>
      <div class="topMenu">
        <router-link to="/works">works</router-link>
      </div>
      <div class="topMenu">
        <router-link to="/events">events</router-link>
      </div>
      <div class="topMenu">
        <a :href="access" target="blank">access</a>
      </div>
      <div class="topMenu">
        <router-link to="/link">link</router-link>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Navbar',
  data: () => ({
    headerColor: '',
    access: '',
  }),
  async mounted() {
    await this.getColor();
  },
  methods: {
    async getColor() {
      const r = await this.$firebase
        .firestore()
        .collection('Look')
        .doc('look')
        .get();

      this.headerColor = r.data().headerColor;
      this.access = r.data().access;
      this.$store.commit('changeTextColor', r.data().textColor);
      this.$store.commit('changeBackground', r.data().background);
    },
  },
};
</script>

<style>
.navbar {
  width: 100%;
}

.topMenu a {
  color: white !important;
}

.topbar {
  width: 100%;
  height: 40px;
  position: fixed;
  /* background-color: burlywood; */
  display: flex;
  justify-content: center;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .navbar {
    width: auto;
    font-size: 0.75rem;
  }
}
</style>
